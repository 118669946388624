@tailwind base;
@tailwind components;
@tailwind utilities;

.eQPgZY{
  display: none !important;
}

.bVsSaQ:nth-child(9){
  display: none !important;
}

.lolgRm label:nth-child(1){
  display: none;
}

.lolgRm label:nth-child(3){
  display: none;
}

.bg-pattern{
  background-image: url("./assets/images/background.png");
}
